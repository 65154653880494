import { HttpClient } from '@angular/common/http'
import { Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { AuthService } from '@src/core/services/auth.service'
import { Environment } from '@src/environments/environment'
import { setStorageJSON } from '@src/helpers/storage.helper'
import { addIcons } from 'ionicons'
import { logOutSharp, personSharp } from 'ionicons/icons'
import { KeycloakProfile } from 'keycloak-js'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-ionic-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  private urlPath = Environment.API_PATH

  public loggedIn: boolean = false
  public user: KeycloakProfile = {}
  public headerMenuItems: Array<{ label: string; icon: string; action?: () => void }> = []
  private routerSubscription?: Subscription;
  private currentRoute?: string;

  private noBackButtonRoutes = [
    '/accueil',
    '/operations/operation/synthese'
  ]

  constructor(
    private readonly auth: AuthService,
    private router: Router,
    private http: HttpClient,
  ) {
    addIcons({ personSharp, logOutSharp })
  }

  async ngOnInit(): Promise<void> {
    this.loggedIn = await this.auth.isLoggedIn()
    // get current route for the first time
    this.currentRoute = this.router.url
    this.routerSubscription = this.router.events.subscribe((data) => {
        if (data instanceof NavigationEnd) {
          // log the current route
          this.currentRoute = data.url
        }
      },
    )

    if (this.loggedIn) {
      this.user = await this.auth.loadUserProfile()
      this.http.get(`${this.urlPath}mobil/me`).subscribe((data) => {
        setStorageJSON('currentUser', data)
      })
      this.headerMenuItems = [
        {
          label: this.user.email ?? 'Utilisateur',
          icon: 'person-sharp',
        },
        {
          label: 'Déconnexion',
          icon: 'log-out-sharp',
          action: () => this.onLogout(),
        },
      ]
    }
  }

  public onLogout(): void {
    this.auth.logout()
  }

  public showBackButton(): boolean {
    return !this.noBackButtonRoutes.includes(this.currentRoute ?? '')
  }

  public previous(): void {
    window.history.back()
  }

}
